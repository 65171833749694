import './Footer.css'
import $ from "jquery"
import phone_icon from './icons/callcalling.svg'
import facebook_icon from './icons/facebook.svg'
import instagram_icon from './icons/instagram.svg'
import logo from './icons/logo.svg'

function Footer() {

    const scrollTo = (e) => {
        var offset = 0;

        if (e === 1) {
            offset = document.getElementById("region-1").offsetTop;
        } else if (e === 2) {
            offset = document.getElementById("region-2").offsetTop;
        } else if (e === 3) {
            offset = document.getElementById("region-3").offsetTop;
        }

        window.scrollTo({
            top: offset - 100,
            behavior: 'smooth'
        });
    };

    return (
        <div className='footer' id='region-3'>
            <div className='c-center'>
                <div className='footer-container'>
                    <img className='logo' src={logo} onClick={() => { scrollTo(0) }} alt="logo" />
                    <div className='menu-entries-footer'>
                        <label className='menu-entry' onClick={() => { scrollTo(1) }}>PRODUSE</label>
                        <label className='menu-entry' onClick={() => { scrollTo(2) }}>DESPRE NOI</label>
                        {/* <label className='menu-entry' onClick={() => { scrollTo(3) }}>CONTACT</label> */}
                    </div>
                    <div className='social-media-container'>
                        <div className='social-media-entry'>
                            <a href="https://www.facebook.com/dozademiere.ro" target="_blank">
                                <img src={facebook_icon} alt="facebook" />
                            </a>
                        </div>
                        <div className='social-media-entry'>
                            <a href="tel:+40741666084">
                                <img src={phone_icon} alt="telefon" />
                            </a>
                        </div>
                        <div className='social-media-entry'>
                            <a href="https://www.instagram.com/dozademiere/" target="_blank">
                                <img src={instagram_icon} alt="instagram" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer; 