import './Menu.css'
import logo from './icons/logo.svg'
import phone_icon from './icons/callcalling.svg'
import $ from "jquery"

function Menu(){
	
	const scrollTo = (e) =>{ 
		var offset = 0;

		if(e === 1){
			offset = document.getElementById("region-1").offsetTop;
		}else if(e === 2){
			offset = document.getElementById("region-2").offsetTop;
		}else if(e === 3){
			offset = document.getElementById("region-3").offsetTop;
		}

		if(e !== 0)
		{
			$("#menu-toggle").click();
		}

		window.scrollTo({
			top: offset - 100,
			behavior: 'smooth'
		  });
	};
	
	return (
		<div className='header'>
			<div className='before-nav-menu c-center'>
				<div className='phone-container'>
					<a href="tel:+40741666084">
						<img src={phone_icon} alt="call"/>
						<label className='tel-label'>0741666084</label>
					</a> 
				</div>
			</div>
			<div className='nav-menu desktop-device'>
				<div className='nav-menu-bar c-center'>
				<div className='logo-container'>
					<img src={logo} onClick={() => {scrollTo(0)}} alt="logo"/>
					<h1 onClick={() => {scrollTo(0)}}>DOZA DE MIERE</h1>
				</div>
				<div className='menu-entries'>
					<label className='menu-entry' onClick={() => {scrollTo(1)}}>PRODUSE</label>
					<label className='menu-entry' onClick={() => {scrollTo(2)}}>DESPRE NOI</label>
					<label className='menu-entry' onClick={() => {scrollTo(3)}}>CONTACT</label>
				</div>
				</div>
			</div>
			<div className='nav-menu mobile-device'>
				<div className='nav-menu-bar c-center top-nav'>
					<div className='logo-container'>
						<img src={logo} onClick={() => {scrollTo(0)}} alt="logo"/>
						<h1 onClick={() => {scrollTo(0)}}>DOZA DE MIERE</h1>
					</div>
					<div className='menu-entries'>
						<input id="menu-toggle" type="checkbox" />
						<label className='menu-button-container' htmlFor='menu-toggle'>
							<div className='menu-button'>
							</div>
						</label>
						<ul className='menu'>
							<li onClick={() => {scrollTo(1)}}>PRODUSE</li>
							<li onClick={() => {scrollTo(2)}}>DESPRE NOI</li>
							<li onClick={() => {scrollTo(3)}}>CONTACT</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Menu;