import './App.css';
import Menu from './Menu';
import HomePage from './HomePage.js';
import Footer from './Footer.js'

function App() {
  return (
    <div className='App'>

      <Menu/>
      <HomePage/>
      <Footer/>

    </div>
  );
}

export default App;